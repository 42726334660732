
.switchAdmin {
    position: relative;
    display: inline-block;
    width: 75px;
    height: 30px;
    background-color: #2c2c2c;
    color: #000;
    -webkit-transition: all 0.6s;
    transition: all 0.6s;
  }
  
  .switchAdmin.off {
    border-radius: 25px;
    padding: 5px;
    cursor: pointer;
    /* background-color: #2c2c2c; */
    background-color: #ffffff;
    margin-right: 10px;
    
    color: #0d1cee;
    border: 1px solid #0d1cee;
    border-radius: 25px;
  }
  .switchAdmin.on {
    border-radius: 25px;
    padding: 5px;
    cursor: pointer;
    content: "En";
    color: #000;
    background-color: #ffffff;
    margin-right: 10px;

    
    color: #EE420D;
    border: 1px solid #EE420D;
    border-radius: 25px;
  }
  
  .switchAdmin.off:before,
  .switchAdmin.off:after {
    -webkit-transition: 0.6s;
    transition: all 0.6s;
  }
  
  .switchAdmin.on:before,
  .switchAdmin.on:after {
    -webkit-transition: 0.6s;
    transition: all 0.6s;
  }
  
  .switchAdmin.off:before,
  .switchAdmin.on:before {
    display: block;
    position: absolute;
    content: "E";
    height: 20px;
    width: 20px;
    left: 5px;
    top: 3px;
    background-color: #ffffff;
    /* background-color: #f2c74e; */
    -webkit-transition: 0.6s;
    transition: all 0.6s;
    -webkit-transition-delay: 0.01s;
    transition-delay: 0.01s;
    /* -moz-box-shadow: inset -8px -8px 6px -6px #c7960f; */
    /* -webkit-box-shadow: inset -8px -8px 6px -6px #c7960f; */
    border-radius: 50%;
    /* box-shadow: inset -8px -8px 6px -6px #c7960f; */
  }
  
  .switchAdmin.off:after {
    display: block;
    position: absolute;
    -o-transform: rotate(-270deg);
    -webkit-transform: rotate(-270deg);
    -ms-transform: rotate(-270deg);
    transform: rotate(-360deg);
    left: 14px;
    top: 15px;
  }
  
  .switchAdmin.on:before {
    content: "A";
    -webkit-transform: translateX(38px);
    -ms-transform: translateX(38px);
    transform: translateX(38px);
  }
  
  .switchAdmin.on:after {
    position: absolute;
    -o-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(360deg);
    left: 60px;
    top: 15px;
  }
  