body {
    margin: 0;
    height: 100%;
    box-sizing: border-box;
    font-family: "Open Sana", sans-serif;
    background: #f2f2f2;
  }
  
  .container {
    max-width: 1024px;
    margin: auto;
    height: 500px;
    /* padding: 50px; */
    display: flex;
    align-items: center;
    justify-content: center;
  }

.dropdown {
    min-width: 100px;
  }
  .dropdown-btn {
      margin-left: 16px;
    cursor: pointer;
  }
  .dropdown-btn:hover {

  }
  .dropdown-btn:focus {
      /* fill: #ADEFD1; */
      

  }

  .dropdown-btn::target-text {
    color: black;
  }
  
  .dropdown-items {
    background: #EEFEFF;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    max-width: 500px;
    min-width: 300px;
    position: absolute;
    
    top: 40px;
    right: 120px;

    margin-top: 5px;
    display: flex;
    flex-direction: column;
    border: 1px solid #EE420D;
    border: 1px solid #EE420D;
    padding: 8px 15px;
    border-radius: 4px;
    max-height: 400px;
    overflow-y: auto
  }

  .dropdown-item:hover {
    background: #ACEFD1 !important;
  }

  .dropdown-item {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    cursor: pointer;
  }
  .dropdown-item:not(first-child) {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .dropdown-item:not(last-child) {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 8px;
    padding-right: 8px;

    margin-top: 4px;
    margin-bottom: 4px;

  }
  
  .isVisible {
    visibility: visible;
  }
  .isHidden {
    visibility: hidden;
  }

  

.notification {
  margin-left: 16px;
}

.newNotification {    
  margin-left: 16px;
  color: #EE420D;
}
  