* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: monstserrat, sans-serif;
}

.containerBody {
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    grid-template-rows: 58px auto 30px;
    background-color: #c6d3f3;
}

.header {
    grid-column: 1 /-1;
    display: flex;
    align-items: center;
    justify-content: end;
    background-color: #c6d3f3;
    position: sticky;
    top: 0;
    z-index: 900;
}

.HeaderDetails {
    display: flex;
    align-items: center;
    justify-content: center;
}

.HeaderUserDetails {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
}

.LanguageToogleDetails {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
}

.mainBody {
    grid-column: 1 /-1;
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    grid-template-rows: auto;
}

.footer {
    grid-column: 1 /-1;
    background-color: #c6d3f3;
    padding: 4px;
}

.menu {
    grid-column: span 2;
    background-color: #c6d3f3;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.menuEx {
    grid-column: span 1;
    background-color: #c6d3f3;
    /* background-color: #e7150e; */
    width: min-content;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.content {
    grid-column: 3 / -1;
    background-color: rgb(255, 255, 255);
    overflow-y: hidden;
}

.contentEx {
    grid-column: 2 / -1;
    background-color: rgb(255, 255, 255);
    overflow-y: hidden;
}

.sidebar {
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    padding: 48px 0 0;
    background-color: #fff;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar-test {
    position: sticky;
    top: 0px;
}

#sidebar-wrapper {
    min-height: 100vh !important;
    width: 100vw;
    margin-left: -1rem;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
}

#page-content-wrapper {
    min-width: 0;
    width: 100%;
}

button {
    background-color: #64b5f6;
    color: black;
    border: 2px solid;
    padding: 8px 12px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    border-radius: 8px;
}

.button {
    background-color: white;
    color: #4caf50;
    border: 1px solid #4caf50;
    padding: 7px 5px;
    text-align: center;
    text-decoration: none;
    font-size: 15px;
    border-radius: 8px;
}

.button:hover {
    background-color: #ADEFD1FF;
    color: black;
}


.buttonClose {
    background-color: white;
    color: #EE420D;
    border: 1px solid #EE420D;
    padding: 7px 9px;
    text-align: center;
    text-decoration: none;
    font-size: 15px;
    border-radius: 8px;
}

.buttonClose:hover {
    background-color: #F17366;
    color: black;
}

.buttonProfile {
    color: #EE420D;
    border: 1px solid #EE420D;
    padding: 7px 9px;
    text-align: center;
    text-decoration: none;
    font-size: 15px;
    border-radius: 8px;
}


.monthlyCheck {
    background-color: #FF7878;
    color:#FFE1AF;
    border: 1px solid #FFE1AF;
    padding: 7px 9px;
    text-align: center;
    font-weight: bold;
    text-decoration: none;
    font-size: 15px;
    border-radius: 8px;
}

.monthlyCheck:hover {
    background-color: white;
    color: black;
}

.tableButton {
    background-color: white;
    color: #4caf50;
    border: 2px solid #4caf50;
    padding: 8px 12px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    border-radius: 8px;
}

.logoutButton {
    background-color: white;
    color: black;
    border: 2px solid #c6d3f3;
    padding: 8px 12px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    margin: 10px;

    border-radius: 8px;
}

h2 {
    color: #888;
    font-size: 28px;
    font-weight: 500;
}

h2 span {
    color: #FE4880;
    font-weight: 700;
}

.LoginForm {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-image: "../../images/backgroundLogin.jpg"; */
    /* background-image: url("../src/images/backgroundLogin.jpg"); */
    background-size: cover;
    background-color:#EDFFFA;
    /* background-color: #cdbfbe; */
    /* background-color: #100c08; */
    /* background-color: #191919; */
    /* background-color: #A9F1F9; */
}

.loginlogo {
    height: 100px;
    width: 300px;
    margin-bottom: 20px;
    /* background-color: powderblue; */
}

.logo-container {
    display: flex;
    justify-content: center;
    /* height: 25px;
width: 20px;
background-image: url("../images/logoCropped.jpg");
background-size:auto;
background-color: #fff; */
}

.logo-container-header {
    position: absolute;
    top: 0;
    left: 0;
    justify-content: left;
    /* height: 25px;
width: 20px;
background-image: url("../images/logoCropped.jpg");
background-size:auto;
background-color: #fff; */
}

form {
    display: block;
    position: relative;
}

form:after {
    content: '';
    display: block;
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    z-index: 1;
    /* background-image: linear-gradient(to bottom right, #ee3c36, #2bdd43 ) */
}

form .form-inner {
    position: relative;
    display: block;
    background-color: #c6d3f3;
    padding: 30px;
    z-index: 2;
    border-radius: 8px;
    box-sizing: border-box;
    font-family: monstserrat, sans-serif;
    color: #7C797C;
}

.error {
    color: #e7150e;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 30px;
}

form .form-inner h2 {
    color: rgb(92, 92, 92);
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 30px;
}

form .form-inner .form-group {
    display: block;
    width: 300px;
    margin-bottom: 15px;
}

form .form-inner .form-group label {
    display: block;
    color: #666;
    font-size: 12px;
    margin-bottom: 5px;
    transition: 0.4s;
}

form .form-inner .form-group:focus-within label {
    color: #FE4880;
}

form .form-inner .form-group input {
    display: block;
    width: 100%;
    padding: 6px 12px;
    background-color: #F8F8F8;
    /*background-color: #A9F1F9;*/
    border-radius: 10px;
    transition: 0.4s;
    border: 0px;
}

form .form-inner .form-group input:focus {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
}

.AdminOptions {
    /* padding: 5px; */
    /* background-color: #56b7f0; */
    width: 100%;
    height: calc(100vh - 88px);
    text-align: left;
    overflow-y: hidden;
}

.adminData {
    text-align: left;
    list-style-type: none;
    max-height: fit-content;
    font-size: 20px;

    margin-top: 20px;
}

.adminOptionLeftSide{
    float: left;
    text-align: left;
    list-style-type: none;
    max-height: fit-content;
    width: 85%;
}

.adminOptionRightSide{
    background-color: #c6d3f3;
    float: right;
    text-align: left;
    list-style-type: none;
    height: 100%;
    
    width: 15%;
}

.adminButton {
    background-color: white;
    color: #489dff;
    border: 1px solid #489dff;
    padding: 7px 5px;
    margin-top: 20px;
    text-decoration: none;
    font-weight: bold;
    font-size: 15px;
    border-radius: 8px;
    width: 160px;
}

.adminButton:hover {
    background-color: #ADEFD1FF;
    color: black;
}

.submitButton {
    background-color: white;
    color: #4caf50;
    border: 1px solid #4caf50;
    padding: 7px 5px;
    /* margin-top: 20px; */
    text-decoration: none;
    font-weight: bold;
    font-size: 15px;
    border-radius: 8px;
    width: 160px;
}

.submitButton:hover {
    background-color: #ADEFD1FF;
    color: black;
}


.AvailableBody {
    /* padding: 5px; */
    /* background-color: #56b7f0; */
    width: 100%;
    height: calc(100vh - 88px);
    text-align: left;
    overflow-y: hidden;
}

.availableData {

    background: #F8F8F8;
    border-radius: 4px;
    border: 1px solid #489dff;

    text-align: left;
    list-style-type: none;
    max-height: 45vh;
    overflow-y: auto;

    padding: 6px;
    margin-right: 1rem;
}

.availableBodyLeftSide{
    float: left;
    text-align: left;
    list-style-type: none;
    max-height: fit-content;
    width: 85%;
}

.availableBodyRightSide{
    background-color: #c6d3f3;
    float: right;
    text-align: left;
    list-style-type: none;

    height: 100%;
    width: 15%;

    padding: 1rem;
}

.availableButton {
    background-color: white;
    color: #489dff;
    border: 1px solid #489dff;
    padding: 7px 5px;
    margin-top: 20px;
    text-decoration: none;
    font-weight: bold;
    font-size: 15px;
    border-radius: 8px;
    width: 160px;
}

.availableButton:hover {
    background-color: #ADEFD1FF;
    color: black;
}

.multiSelectContainer input {
    width: 50px;
    font-size: 13px;
}

.multiSelectContainer {
    position: relative;
    text-align: left;
    width: 100%;
    background: white;
    border-radius: 4px;
}

.profilePage {
    padding: 30px;
    background-color: #fffafa;
    /* height: 20vh; */
    width: 100%;
    text-align: center;
}

.show {
    display: block;
}

.hide {
    display: none;
}

.burgerMenuHide {
    /* display: hidden; */
    visibility: hidden;
}

.burgerMenuShow {
    /* display: block; */
    visibility: visible;
    /* grid-column: span 1; */
}

.singleSelect {
    padding-right: 20px;
    margin-top: 10px;
}

element.style {
    margin-top: 10px;
}

.custom-menu svg {
    fill: #616161;
}

.custom-menu svg:hover {
    fill: #212121;
    transform: scale(1.1) !important;
}

.nav-link {
    color: #616161 !important;
}

.nav-link:hover {
    color: #212121 !important;
}

.sidebar_text{
    font-size: 9px;
    text-align: center;
}

.sideMenuHover:hover{
    margin-right: 3px;
    background-color: #489dff !important;
    border-radius: 3px !important;
    color: #ffffff !important;
}

.sideMenuSelected{
    margin-right: 3px;
    background-color: #489dff !important;
    border-radius: 3px !important;
    color: #ffffff !important;
}

.sideMenu{
    border-radius: 3px !important;
    margin-right: 3px;
}

.sideMenu:hover{
    background-color: #489dff !important;
    border-radius: 3px !important;
    color: #ffffff !important;
}

.redText {
    color: #489dff;
}